@import "~antd/dist/antd.css";

.layout-content {
  padding: 24px;
  background: white;
}
.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

body {
  background: #f0f2f5;
}
